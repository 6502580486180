var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block w-100" },
    [
      _c(
        "main-button",
        { staticClass: "open-popup-btn", attrs: { "on-click": _vm.openPopup } },
        [_vm._v(" Drink Elixir ")]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.popupOpen,
            title: "Elixir Drink Pub",
            width: "650px",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.popupOpen = !_vm.popupOpen
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "v-tabs-items",
                {
                  staticClass: "transparent overflow-visible",
                  model: {
                    value: _vm.selectedTab,
                    callback: function ($$v) {
                      _vm.selectedTab = $$v
                    },
                    expression: "selectedTab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { key: "wizards_selector" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0 mb-4" },
                        [
                          _c("h3", { staticClass: "pb-2" }, [
                            _vm._v("Select wizard: "),
                          ]),
                          _vm.ownedWizards.length > 0
                            ? _c(
                                "v-row",
                                _vm._l(_vm.ownedWizards, function (wizard) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: `wizard_${wizard.id}`,
                                      staticClass:
                                        "d-flex align-center justify-center",
                                      attrs: { cols: "6", md: "3", sm: "4" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectWizard(wizard)
                                        },
                                      },
                                    },
                                    [
                                      _c("mini-wizard", {
                                        attrs: { item: wizard },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c("div", [
                                _c("h3", { staticClass: "text-center" }, [
                                  _vm._v("- No Wizards Owned -"),
                                ]),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "main_tab" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0" },
                        [
                          _c("hr", { staticClass: "mb-6" }),
                          _c(
                            "v-row",
                            {
                              staticClass: "round-borders-highlight my-4 mx-8",
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-end",
                                  attrs: { cols: "12", sm: "5" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.wizardImageSrc,
                                      contain: "",
                                      "lazy-src": "/img/wizard-shadow_250.png",
                                      "max-height": "20vmax",
                                      "max-width": "150px",
                                    },
                                  }),
                                  _vm.wizardName
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hero-description d-flex flex-row align-top",
                                        },
                                        [
                                          _c(
                                            "h4",
                                            { staticClass: "ext-center" },
                                            [_vm._v(_vm._s(_vm.wizardName))]
                                          ),
                                          !_vm.wizardDTO
                                            ? _c("div", {
                                                staticClass: "button-square",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openWizardSelection()
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "main-button",
                                            {
                                              attrs: {
                                                "on-click":
                                                  _vm.openWizardSelection,
                                              },
                                            },
                                            [_vm._v(" Select Wizard ")]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-center",
                                  attrs: { cols: "12", sm: "2" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "frames", large: "" } },
                                    [_vm._v("mdi-plus")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-end",
                                  attrs: { cols: "12", sm: "5" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.elixirImageSrc,
                                      contain: "",
                                      "lazy-src": "/img/elixir-shadow_250.png",
                                      "max-height": "10vmax",
                                      "max-width": "150px",
                                    },
                                  }),
                                  _vm.elixirName
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hero-description d-flex flex-row align-top",
                                        },
                                        [
                                          _c(
                                            "h4",
                                            { staticClass: "pr-2 text-center" },
                                            [_vm._v(_vm._s(_vm.elixirName))]
                                          ),
                                          !_vm.elixirDTO
                                            ? _c("div", {
                                                staticClass: "button-square",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openElixirSelection()
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "main-button",
                                            {
                                              attrs: {
                                                "on-click":
                                                  _vm.openElixirSelection,
                                              },
                                            },
                                            [_vm._v(" Select Elixir ")]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _vm.selectedElixir
                                  ? _c("div", [
                                      _c("hr", { staticClass: "mb-4" }),
                                      _c(
                                        "h3",
                                        { staticClass: "white--text mb-4" },
                                        [_vm._v(_vm._s(_vm.elixirName))]
                                      ),
                                      _vm.elixirPower
                                        ? _c(
                                            "div",
                                            { staticClass: "white--text" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.elixirPower) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.elixirDescription
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-italic purple--text text--lighten-4",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.elixirDescription
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "caption pa-2 mt-4 burn-warning white--text",
                                        },
                                        [
                                          _vm._v(
                                            " Drinking Elixir will burn selected Wizard and replace with new one. New one will be copy of burned one, with new ID and modifications according to elixir effect. "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "elixirs_selector" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0 mb-4" },
                        [
                          _c("h3", { staticClass: "pb-2" }, [
                            _vm._v("Select elixir: "),
                          ]),
                          _vm.ownedElixirs.length > 0
                            ? _c(
                                "v-row",
                                _vm._l(_vm.ownedElixirs, function (elixir) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: `elixir_${elixir.id}`,
                                      staticClass:
                                        "d-flex align-center justify-center",
                                      attrs: { cols: "6", md: "3", sm: "4" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectElixir(elixir)
                                        },
                                      },
                                    },
                                    [
                                      _c("mini-enhancement", {
                                        attrs: { item: elixir },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c("div", [
                                _c("h3", { staticClass: "text-center" }, [
                                  _vm._v("- No Elixirs Owned -"),
                                ]),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pa-0 pt-4" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0 mx-sm-16" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          !(_vm.selectedTab === _vm.MAIN_TAB)
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "main-button",
                                    { attrs: { "on-click": _vm.goHome } },
                                    [_vm._v(" Back ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedTab === _vm.MAIN_TAB
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "main-button",
                                    {
                                      attrs: {
                                        disabled: _vm.elixirsAllowed,
                                        loading:
                                          _vm.checkingElixirsAllowance ||
                                          _vm.elixirsAllowingInProgress,
                                        "on-click": _vm.allowElixirs,
                                      },
                                    },
                                    [_vm._v(" Allow Elixirs ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedTab === _vm.MAIN_TAB
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "main-button",
                                    {
                                      attrs: {
                                        disabled: _vm.wizardsAllowed,
                                        loading:
                                          _vm.checkingWizardsAllowance ||
                                          _vm.wizardsAllowingInProgress,
                                        "on-click": _vm.allowWizards,
                                      },
                                    },
                                    [_vm._v(" Allow Wizards ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _vm.selectedTab === _vm.MAIN_TAB
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "main-button",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.validationErrors
                                                              .length > 0,
                                                          "on-click":
                                                            _vm.drinkElixir,
                                                          glow: "",
                                                        },
                                                      },
                                                      [_vm._v(" Drink Elixir ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4291156756
                                      ),
                                    },
                                    [
                                      _c("div", [
                                        _vm.validationErrors.length === 0
                                          ? _c("span", [
                                              _vm._v("Click to drink elixir"),
                                            ])
                                          : _c(
                                              "ul",
                                              _vm._l(
                                                _vm.validationErrors,
                                                function (error, i) {
                                                  return _c(
                                                    "li",
                                                    { key: "err_" + i },
                                                    [
                                                      _vm._v(
                                                        _vm._s(error) + " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.waitingForConfirmation,
            persistent: "",
            title: "Waiting for drinking confirmation",
            width: "750",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.waitingForConfirmation = !_vm.waitingForConfirmation
            },
          },
        },
        [
          _c("v-img", {
            staticClass: "round-borders-highlight",
            attrs: {
              contain: "",
              "max-width": "80vw",
              src: "/img/transformation/waiting.png",
            },
          }),
          _c("v-progress-linear", {
            staticClass: "mt-4",
            attrs: { color: "white", height: "5", indeterminate: "" },
          }),
        ],
        1
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.transformingWizard,
            persistent: "",
            title: "Wizard is transforming",
            width: "750",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.transformingWizard = !_vm.transformingWizard
            },
          },
        },
        [
          _c("v-img", {
            staticClass: "round-borders-highlight",
            attrs: {
              contain: "",
              "max-width": "80vw",
              src: "/img/transformation/transforming.png",
            },
          }),
          _c("v-progress-linear", {
            staticClass: "mt-4",
            attrs: { color: "white", height: "5", indeterminate: "" },
          }),
        ],
        1
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.newWizardTransformed,
            "highlight-title": "",
            title: "Congratulations!",
            width: "500px",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.newWizardTransformed = !_vm.newWizardTransformed
            },
          },
        },
        [
          _c("hr", { staticClass: "mt-2" }),
          _vm.transformedWizard !== null
            ? _c(
                "div",
                {
                  staticClass:
                    "text--primary d-flex flex-column align-center justify-space-between",
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "round-borders-highlight my-4" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex flex-column align-center justify-end",
                          attrs: { cols: "12", sm: "5" },
                        },
                        [
                          _c("v-img", {
                            staticClass: "mb-4",
                            attrs: {
                              src: _vm.wizardImageSrc,
                              contain: "",
                              "lazy-src": "/img/wizard-shadow_250.png",
                              "max-height": "20vmax",
                              "max-width": "150px",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "hero-description d-flex flex-row align-center",
                            },
                            [
                              _c("h4", { staticClass: "pr-2 text-center" }, [
                                _vm._v(_vm._s(_vm.wizardName)),
                              ]),
                              !_vm.wizardDTO
                                ? _c("div", {
                                    staticClass: "button-square",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openWizardSelection()
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex flex-column align-center justify-center",
                          attrs: { cols: "12", sm: "2" },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "hidden-xs-only",
                              attrs: { color: "golden", large: "" },
                            },
                            [_vm._v("mdi-arrow-right")]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "hidden-sm-and-up",
                              attrs: { color: "golden", large: "" },
                            },
                            [_vm._v("mdi-arrow-down")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex flex-column align-center justify-end",
                          attrs: { cols: "12", sm: "5" },
                        },
                        [
                          _c("v-img", {
                            staticClass: "mb-4",
                            attrs: {
                              src: _vm.transformedWizard.thumbnail_300,
                              contain: "",
                              "lazy-src": "/img/wizard-shadow_250.png",
                              "max-height": "20vmax",
                              "max-width": "150px",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "hero-description d-flex flex-row align-center",
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass: "pr-2 text-center golden--text",
                                },
                                [_vm._v(_vm._s(_vm.transformedWizard.name))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "caption pa-4 text-center burn-warning mb-4",
                    },
                    [
                      _vm._v(
                        " You've transformed your wizard " +
                          _vm._s(_vm.wizardName) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" to a new wizard "),
                      _c("span", { staticClass: "golden--text" }, [
                        _vm._v(_vm._s(_vm.transformedWizard.name)),
                      ]),
                    ]
                  ),
                  _c("span", [_vm._v("You can check it on")]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "Wizard",
                          params: { id: _vm.transformedWizard.id },
                        },
                        color: "purple",
                      },
                    },
                    [
                      _vm._v(
                        " Wizard #" + _vm._s(_vm.transformedWizard.id) + " "
                      ),
                    ]
                  ),
                  _c("div"),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            id: "ErrorConfirmation",
            model: _vm.errorDialogOpen,
            title: "Error occurred",
            width: "auto",
          },
          on: { dialogClosed: _vm.handleErrorDialogClose },
        },
        [
          _c("img", {
            staticClass: "error-image rounded",
            attrs: { src: "/img/summoning/png/error.png" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }