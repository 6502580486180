<template>
    <v-row v-if="wizard.id" :data-id="wizard.id">
        <v-col cols="10" lg="6" md="7" offset="1" offset-md="0">
            <v-row class="section-img-frame" no-gutters>
                <v-col class="wizard-portrait"
                       cols="12"
                       sm="9"
                >
                    <img class="wizard-bg" src="/img/wizard/frame-wizard.svg"/>
                    <div class="wizard-title">
                        <span>{{ wizard.name }}</span>
                    </div>
                    <img :src="wizard.image" class="wizard-image"/>
                </v-col>
                <div class="d-flex d-sm-none w-100 py-2 mx-8 wizard-spells-vertical-connector"/>
                <v-col v-if="isWizardMature && wizardSpells.length > 0"
                       class="d-flex justify-start align-stretch" cols="12"
                       sm="3">
                    <div class="wizard-spells-horizontal-connector my-16 px-4 d-none d-sm-flex align-center">
                    </div>
                    <v-fade-transition group hide-on-leave
                                       class="wizard-spells w-100 py-2 py-sm-8 px-5 px-sm-4 px-md-2 px-xl-5 pt-14 pt-sm-10 pt-md-12 mr-0 mr-sm-4 mr-md-0 common-color common-bg box-decorated-edges-mini d-flex flex-row flex-wrap flex-sm-column align-start align-md-center justify-space-around">
                        <v-select
                            key="spellSelector"
                            v-model="displayGameSpells"
                            :items="[{name: 'Temporarly', value: true},{name: 'NFT', value: false},]"
                            background-color="buttonBg"
                            class="spellSelector"
                            color="frames"
                            dense
                            item-color="frames"
                            item-text="name"
                            item-value="value"
                            single-line
                            solo
                        ></v-select>

                        <div v-for="spell in wizardSpells" :key="'spellIcon-' + spell.in_game_id"
                             :class="spell.classes"
                             class="single-spell d-flex flex-column align-center text-wrap w-100"
                             @click="openPopup(spell.in_game_id)"
                        >
                            <div class="icon-container mt-1">
                                <img :src="spell.icon" class="bordered-icon">
                            </div>
                            <div class="spell-text pt-1">{{ spell.name }}</div>

                            <dialog-box id="spell.in_game_id"
                                        :model="openPopups[spell.in_game_id]"
                                        width="650"
                                        v-on:dialogClosed="closePopup(spell.in_game_id)">
                                <div class="spell-popup common-color">
                                    <v-row class="popup-header pb-4 mb-4 d-flex align-center" no-gutters>
                                        <v-col class="pr-2 py-1" cols="3">
                                            <img :src="spell.icon" class="bordered-icon">
                                        </v-col>
                                        <v-col cols="9">
                                            <h2>{{ spell.name }}</h2>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="10" sm="4">
                                            <div class="highlighted-part">
                                                <img :src="spell.partImage">
                                            </div>
                                        </v-col>
                                        <v-col class="spell-description" cols="10" sm="8">
                                            <div
                                                v-if="spell.flavour_description !== undefined && spell.flavour_description !== null"
                                                class="font-italic">
                                                "{{ spell.flavour_description }}"
                                                <br/>
                                                <br/>
                                            </div>
                                            <div><span>{{ spell.technical_description }}</span></div>
                                            <br>
                                            <div>Spell type: <span :class="spell.classes"
                                                                   class="text-capitalize"> {{ spell.type }}</span>
                                            </div>
                                            <div>Spell element: <span :class="spell.element"
                                                                      class="text-capitalize"> {{
                                                    spell.element
                                                }}</span>
                                            </div>
                                            <div
                                                v-if="spell.mechanic_name !== undefined && spell.mechanic_name !== null">
                                                Mechanic: <span
                                                class="text-capitalize"> {{ spell.mechanic_name }}</span></div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </dialog-box>
                        </div>
                    </v-fade-transition>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="10" lg="6" md="5" offset="1" offset-md="0">
            <div
                v-if="isOfferActive"
                class="box-decorated-edges-mini common-bg section-buttons"
            >
                <asset-take-offer-popup
                    :activeOffer="activeOffer"
                />
            </div>
            <v-row v-if="isOwner && !isOfferActive"
                   class="box-decorated-edges-mini common-bg section-buttons"
                   dense
            >
                <v-col cols="12" lg="12" md="12">
                    <wizard-transform-popup :wizardDTO="wizardDTO"/>
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="6">
                    <wizard-transfer-popup :nft="wizard"/>
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="6">
                    <asset-create-offer-popup
                        :assetDTO="wizardDTO"
                        :assetType="assetType"
                    />
                </v-col>
            </v-row>
            <div class="section-details common-color common-bg box-decorated-edges-mini pa-8">
                <div class="information text-capitalize">
                    <p class="text-truncate">
                        Owner: <span v-title :title="assetOwner">{{ assetOwner | shortAddress }}</span>
                        <copy-button :text="assetOwner"></copy-button>
                    </p>
                    <p v-if="wizardDTO">
                        Wizard created on:
                        <span v-title :title='wizardDTO.created_on | moment("HH:MM Do MMMM YYYY")' class="text-no-wrap">
                            {{ wizardDTO.created_on | moment("Do MMMM YYYY") }}
                        </span>
                    </p>
                    <p v-else>
                        Created on:
                        <span v-title :title='wizard.created_on | moment("HH:MM Do MMMM YYYY")' class="text-no-wrap">
                            {{ wizard.created_on | moment("Do MMMM YYYY") }}
                        </span>
                    </p>
                    <br/>
                    <p v-if="!isWizardMature">
                        Mature in:
                        <timer :deadline-string="wizard.wizarre.mature_on"
                               :title="wizard.wizarre.mature_on">
                        </timer>
                        <v-progress-linear
                            v-if="!isWizardMature"
                            :value="maturationProgress"
                            background-opacity="0"
                            class="level-progress d-sm-inline-block mx-0 mx-sm-4 mb-4 mb-sm-0"
                            color="white"
                            height="11px"
                        />
                    </p>
                    <p v-else>
                        Level: <span>{{ wizard.wizarre.experience / 1000 + 1 }}</span>
                        <v-progress-linear :value="(wizard.wizarre.experience%1000)/10"
                                           background-opacity="0"
                                           class="level-progress d-sm-inline-block mx-0 mx-sm-4 mb-4 mb-sm-0"
                                           color="white"
                                           height="11px"
                        />
                    </p>
                    <p>
                        Inner Elements:
                        <span>
                                <span class="text-no-wrap">
                                    <icon-element :element="element_1" class="element-icon"
                                                  img-size="26px"></icon-element>
                                    {{ element_1 }}
                                </span>
                        </span>
                        <span v-if="element_2" class="text-no-wrap">
                            <icon-element v-if="element_2" :element="element_2" class="element-icon second-element-icon"
                                          img-size="26px"></icon-element>
                            {{ element_2 }}
                        </span>
                    </p>
                    <p>
                        Soul Fragments: <span>{{ wizard.wizarre.souls_remaining }}/{{ wizard.wizarre.souls_max }}</span>
                    </p>
                    <p>
                        Personality:
                        <span v-if="wizard.wizarre.personality">{{ wizard.wizarre.personality }}</span>
                        <span v-else class="text-none">Will be uncovered when the Wizard matures</span>
                    </p>
                </div>
                <div class="section-spacer"></div>
                <div class="statistics">
                    <p class="mb-2 font-weight-bold">Statistics:</p>
                    <p v-if="!isWizardMature"><span>Will be uncovered when the Wizard matures</span></p>
                    <v-row v-else no-gutters>
                        <v-col cols="12" lg="7" md="12" sm="6" xl="6">
                            <WizardStatisticsGraph
                                :statistics=wizardDTO.wizarre.statistics
                            >
                            </WizardStatisticsGraph>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </div>
                <div v-if="wizard.wizarre.reincarnated_from || wizard.wizarre.reincarnated_into">
                    <div class="section-spacer"></div>
                    <p class="font-weight-bold"> Reincarnations: </p>
                    <div
                        class="reincarnated w-100 d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap">
                        <div v-if="wizard.wizarre.reincarnated_from"
                             class="reincarnation-previous d-flex flex-column align-center">
                            <router-link
                                v-if="reincarnatedFrom"
                                :to="{ name: 'Wizard', params: {id: reincarnatedFrom.id}}"
                            >
                                <mini-wizard
                                    :item="reincarnatedFrom"
                                ></mini-wizard>
                            </router-link>
                            <span> Previous </span>
                        </div>
                        <div v-if="wizard.wizarre.reincarnated_into"
                             class="reincarnation-next d-flex flex-column align-center">
                            <router-link
                                v-if="reincarnatedInTo"
                                :to="{ name: 'Wizard', params: {id: reincarnatedInTo.id}}"
                            >
                                <mini-wizard
                                    :item="reincarnatedInTo"
                                ></mini-wizard>
                            </router-link>
                            <span> Next </span>
                        </div>
                    </div>
                </div>
                <div class="parents">
                    <div class="section-spacer"></div>
                    <p class="font-weight-bold">Ancestors:</p>
                    <h3 v-if="wizard.wizarre.is_genesis" class="yellow--text">
                        Genesis Wizard
                    </h3>
                    <h3 v-else-if="wizard.wizarre.is_firstborn" class="yellow--text"
                    >
                        Firstborn Wizard
                    </h3>
                    <h3 v-else-if="wizard.wizarre.summoned_by.length === 0" class="yellow--text"
                    >
                        Ephemeral Ancestors
                    </h3>
                    <div v-else
                         class="w-100 d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap">
                        <div v-for="item in summonedByWizards" :key="'wiz'+item.id">
                            <router-link
                                v-if="item.id"
                                :to="{ name: 'Wizard', params: {id: item.id}}"
                            >
                                <mini-wizard
                                    :item="item"
                                ></mini-wizard>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-if="summonedWizards.length > 0"
                     class="children">
                    <div class="section-spacer"></div>
                    <p class="font-weight-bold">Descendants:</p>
                    <div v-if="summonedWizards.length > 0"
                         class="d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap">
                        <div v-for="item in summonedWizards" :key="item.image">
                            <router-link :to="{ name: 'Wizard', params: {id: item.id}}">
                                <mini-wizard
                                    :item="item"
                                ></mini-wizard>
                            </router-link>
                        </div>
                    </div>
                    <div v-else>
                        <span>This Wizard has no descendants</span>
                    </div>
                </div>
                <div v-if="usedIngredients.length > 0"
                     class="ingredients">
                    <div class="section-spacer"></div>
                    <p class="font-weight-bold">Enhancements:</p>
                    <div class="d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap">
                        <div v-for="(item, i) in usedIngredients" :key="`enh_${i}_${item.image}`">
                            <mini-enhancement
                                :item="item"
                                hide-amount
                            ></mini-enhancement>
                        </div>
                    </div>
                </div>

            </div>
        </v-col>
    </v-row>
</template>

<script>
import IconElement from "../../icon-element";
import MiniWizard from "./mini-wizard";
import {apiServiceVue} from "@/services/apiService.vue";
import Vue from "vue";
import MiniEnhancement from "../enhancements/mini-enhancement";
import Timer from "../../timer";
import DialogBox from "../../dialog-box";
import WizardTransferPopup from "./wizard-transfer-popup";
import AssetCreateOfferPopup from "@/components/market/asset-create-offer-popup";
import {AssetType} from "@/classes/asset/AssetType";
import AssetDTO from "@/classes/asset/AssetDTO";
import AssetTakeOfferPopup from "@/components/market/asset-take-offer-popup";
import OfferDTO from "@/classes/market/OfferDTO";
import {OfferStatus} from "@/classes/market/OfferStatus";
import CopyButton from "../../ui/copy-button";
import WizardTransformPopup from "@/components/assets/wizards/wizard-transform-popup";
import WizardStatisticsGraph from "@/components/assets/wizards/wizard-statistics-graph";

const SPELL_PART_MAP = {
    buff: 'emblem',
    offensive: 'hat',
    defensive: 'robe',
    ultimate: 'stuff',
}
const GENDER_MAP = {
    man: "male",
    woman: "female"
}

export default {
    components: {
        WizardStatisticsGraph,
        WizardTransformPopup,
        CopyButton,
        AssetTakeOfferPopup,
        AssetCreateOfferPopup,
        WizardTransferPopup,
        DialogBox,
        Timer,
        MiniEnhancement,
        MiniWizard,
        IconElement
    },
    props: {
        wizard: Object,
        isOwner: {
            type: Boolean,
            default: false
        },
        activeOffer: {
            type: OfferDTO,
            default: undefined,
        }
    },
    data() {
        return {
            assetType: AssetType.WIZARD,
            summonedByWizards: [],
            reincarnatedInTo: undefined,
            reincarnatedFrom: undefined,
            usedIngredients: [],
            summonedWizards: [],
            openPopups: {},
            displayGameSpells: true,
            wizardGameSpells: [],
        }
    },
    mounted() {
        // Fetch parents
        if (this.wizard.wizarre.summoned_by && this.wizard.wizarre.summoned_by.length > 0) {
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'many/' + this.wizard.wizarre.summoned_by.join(',');
            apiServiceVue.get(url)
                .then(res => {
                    this.summonedByWizards = res.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Parents failed'
                    });
                });
        }

        // Fetch reincarnated from
        if (this.wizard.wizarre.reincarnated_from) {
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'many/' + this.wizard.wizarre.reincarnated_from;
            apiServiceVue.get(url)
                .then(res => {
                    this.reincarnatedFrom = res.data[0];
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch reincarnated from'
                    });
                });
        }

        // Fetch reincarnated in to
        if (this.wizard.wizarre.reincarnated_into) {
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'many/' + this.wizard.wizarre.reincarnated_into;
            apiServiceVue.get(url)
                .then(res => {
                    this.reincarnatedInTo = res.data[0];
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch reincarnated in to'
                    });
                });
        }

        if (this.wizard.wizarre?.ingredients?.length > 0) {
            const url = process.env.VUE_APP_ENHANCEMENT_NFT_SERVER_URL + this.wizard.wizarre.ingredients;
            apiServiceVue.get(url)
                .then(res => {
                    this.usedIngredients = this.wizard.wizarre.ingredients.map(id => res.data[id]);
                });
        }

        // Fetch children
        if (this.wizard.wizarre.summoned && this.wizard.wizarre.summoned.length > 0) {
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'many/' + this.wizard.wizarre.summoned.join(',');
            apiServiceVue.get(url)
                .then(res => {
                    this.summonedWizards = res.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Children failed'
                    });
                });
        }

        // Fetch Game Spells
        if (this.isWizardMature) {
            const url = `${process.env.VUE_APP_WIZARD_NFT_SERVER_URL}many/${this.wizard.id}/spells/ea`;

            apiServiceVue.get(url)
                .then(res => {
                    this.wizardGameSpells = res
                        .data[this.wizard.id]
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Game Spells failed'
                    });
                });
        }
    },
    computed: {
        maturationProgress() {
            const bornTimestamp = new Date(this.wizard.created_on).getTime();
            const matureTimestamp = new Date(this.wizard.wizarre.mature_on).getTime();
            const nowTimestamp = Date.now();

            const maturationPeriod = matureTimestamp - bornTimestamp;
            const maturationProgress = nowTimestamp - bornTimestamp;

            return Math.round((maturationProgress / maturationPeriod) * 100) || 1;
        },
        isOfferActive() {
            return Boolean(this?.activeOffer?.status === OfferStatus.AVAILABLE)
        },
        wizardDTO() {
            return new AssetDTO(this.wizard)
        },
        element_1() {
            if (this.wizard.wizarre.elements && this.wizard.wizarre.elements[0]) {
                return this.wizard.wizarre.elements[0];
            }
        },
        element_2() {
            if (this.wizard.wizarre.elements && this.wizard.wizarre.elements[1]) {
                return this.wizard.wizarre.elements[1];
            }
        },
        isWizardMature() {
            return !this.wizard.wizarre.mature_on;
        },
        wizardSpells() {
            const spellsToDisplay = this.displayGameSpells ? this.wizardGameSpells : this.wizard.wizarre?.spells

            if (!spellsToDisplay?.length) {
                return []
            }
            return spellsToDisplay
                .map(spell => {
                    return {
                        ...spell,
                        icon: `/img/spells/120x120/${spell.type}/${spell.element}/${spell.icon}`,
                        partImage: `/img/wizard/highlighted-parts/wizard-${GENDER_MAP[this.wizard.wizarre.gender]}-${SPELL_PART_MAP[spell.type]}-${spell.element}@72x.png`,
                        classes: [
                            spell.type,
                        ]
                    }
                })
                .sort((s1, s2) => s1.type.localeCompare(s2.type))
        },
        assetOwner() {
            return String(this.activeOffer?.creator || this.wizard.owner);
        },
    },
    methods: {
        openPopup(id) {
            Vue.set(this.openPopups, id, true)
        },
        closePopup(id) {
            Vue.set(this.openPopups, id, false)
        },
    }
};
</script>

<style lang="scss" scoped>
.common-color {
    color: #b69366;
}

.common-bg {
    background-color: rgba(42, 28, 51);
}

.highlighted-part {
    height: 200px;
}

.single-spell {
    cursor: pointer;

    .spell-text, .bordered-icon {
        transition-duration: 250ms;
        transition-property: color, box-shadow;
    }

    &:hover .bordered-icon {
        box-shadow: 0 0 0 2px var(--v-golden-base) !important;
    }

    &:hover .spell-text {
        color: var(--v-golden-base);
    }

    .spell-text {
        margin: 0 2px;
    }
}

.bordered-icon {
    border: 4px solid black;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #b49265;
    aspect-ratio: 1;
}

.section-img-frame {
    max-width: calc(85vh - 125px);
    height: auto;
    margin: auto;
    text-align: center;
    position: sticky;
    top: 75px;

    .wizard-portrait {
        position: relative;

        .wizard-bg {
            width: 100%;
            height: auto;
            position: relative;
            display: block;
        }

        .wizard-title {
            width: 86%;
            height: 13%;
            top: 0.5%;
            left: 7%;
            right: auto;
            margin: auto;
            position: absolute;
            color: #000;
            font-size: 160%;
            line-height: 120%;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        .wizard-image {
            top: 15%;
            width: 90%;
            left: 2.5%;
            right: auto;
            margin: auto;
            position: absolute;
            display: block;
        }
    }

    .wizard-spells {

        .spellSelector {
            position: absolute;
            top: 0.5px;
            left: 1px;
            width: calc(100% - 2px);
        }

        .icon-container {
            width: 100%;
            margin-bottom: -2.5%;
            cursor: pointer;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        .ultimate {
            color: rgba(137, 108, 171);

            img {
                box-shadow: 0 0 0 2px rgba(137, 108, 171);
            }
        }
    }

    .wizard-spells-vertical-connector {
        border-left: 4px solid rgba(181, 147, 101);
        border-right: 4px solid rgba(181, 147, 101);
    }

    .wizard-spells-horizontal-connector {
        height: 65%;
        margin-left: -25px;
        border-top: 4px solid rgba(181, 147, 101);
        border-bottom: 4px solid rgba(181, 147, 101);
    }

}

.spell-popup {
    img {
        width: 80%;
        height: auto;
        object-fit: contain;
    }

    span {
        color: white;
    }

    .popup-header {
        font-size: 175%;
        border-bottom: 2px solid #b49265;
    }

    .spell-description {
        font-size: 120%;

        .fire {
            color: #fd4a24;
        }

        .nature {
            color: #7fc73f;
        }

        .water {
            color: #65bafe;
        }

        .buff {
            color: #a48a69;
        }

        .defensive {
            color: #82621f;
        }

        .offensive {
            color: #c75309;
        }

        .ultimate {
            color: #8867b3;
        }
    }
}

.section-details {
    .section-spacer {
        height: 0;
        border-top: 4px solid #382849;
        margin: 32px -32px
    }

    p {
        margin-bottom: 4px;
    }

    span {
        color: rgba(255, 255, 255);
    }

    .level-progress {
        max-width: 200px;
        border: 2.5px solid #bb9867;
    }

    .element-icon {
        margin-top: -10px;
    }

    .reincarnation-previous, .reincarnation-next {
        span {

        }
    }
}

@media only screen and (max-width: $lg) {
    .section-img-frame {
        width: 100%;
    }
    .wizard-spells {
        font-size: 85%;
        line-height: 110%;

        .icon-container {
            width: 75% !important;
        }
    }
}

@media only screen and (max-width: $md) {
    .wizard-spells {
        font-size: 65%;
    }
}

@media only screen and (max-width: $sm) {
    .wizard-spells {
        font-size: 95%;
        line-height: 115%;

        .icon-container {
            width: 90% !important;
        }
    }

    .spell-popup {
        img {
            width: 60%;
        }

        .popup-header {
            font-size: 135% !important;
        }
    }
}


@media only screen and (max-width: $xs) {
    .section-img-frame {
        .wizard-title {
            font-size: 100%;
        }
    }

    .single-spell {
        width: 34%;
        margin: 4px;

        .wizard-spells {
            font-size: 75%;
            line-height: 120%;

            .icon-container {
                width: 75% !important;
            }
        }
    }

    .spell-popup {
        img.bordered-icon {
            width: 45px;
            margin-right: 15px;
        }

        .popup-header {
            font-size: 80% !important;
        }
    }
}

@media only screen and (max-height: 550px) {

    .section-img-frame {
        max-width: 330px;
    }
}
</style>
