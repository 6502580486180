var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.wizard.id
    ? _c(
        "v-row",
        { attrs: { "data-id": _vm.wizard.id } },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "10",
                lg: "6",
                md: "7",
                offset: "1",
                "offset-md": "0",
              },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "section-img-frame",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "wizard-portrait",
                      attrs: { cols: "12", sm: "9" },
                    },
                    [
                      _c("img", {
                        staticClass: "wizard-bg",
                        attrs: { src: "/img/wizard/frame-wizard.svg" },
                      }),
                      _c("div", { staticClass: "wizard-title" }, [
                        _c("span", [_vm._v(_vm._s(_vm.wizard.name))]),
                      ]),
                      _c("img", {
                        staticClass: "wizard-image",
                        attrs: { src: _vm.wizard.image },
                      }),
                    ]
                  ),
                  _c("div", {
                    staticClass:
                      "d-flex d-sm-none w-100 py-2 mx-8 wizard-spells-vertical-connector",
                  }),
                  _vm.isWizardMature && _vm.wizardSpells.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-start align-stretch",
                          attrs: { cols: "12", sm: "3" },
                        },
                        [
                          _c("div", {
                            staticClass:
                              "wizard-spells-horizontal-connector my-16 px-4 d-none d-sm-flex align-center",
                          }),
                          _c(
                            "v-fade-transition",
                            {
                              staticClass:
                                "wizard-spells w-100 py-2 py-sm-8 px-5 px-sm-4 px-md-2 px-xl-5 pt-14 pt-sm-10 pt-md-12 mr-0 mr-sm-4 mr-md-0 common-color common-bg box-decorated-edges-mini d-flex flex-row flex-wrap flex-sm-column align-start align-md-center justify-space-around",
                              attrs: { group: "", "hide-on-leave": "" },
                            },
                            [
                              _c("v-select", {
                                key: "spellSelector",
                                staticClass: "spellSelector",
                                attrs: {
                                  items: [
                                    { name: "Temporarly", value: true },
                                    { name: "NFT", value: false },
                                  ],
                                  "background-color": "buttonBg",
                                  color: "frames",
                                  dense: "",
                                  "item-color": "frames",
                                  "item-text": "name",
                                  "item-value": "value",
                                  "single-line": "",
                                  solo: "",
                                },
                                model: {
                                  value: _vm.displayGameSpells,
                                  callback: function ($$v) {
                                    _vm.displayGameSpells = $$v
                                  },
                                  expression: "displayGameSpells",
                                },
                              }),
                              _vm._l(_vm.wizardSpells, function (spell) {
                                return _c(
                                  "div",
                                  {
                                    key: "spellIcon-" + spell.in_game_id,
                                    staticClass:
                                      "single-spell d-flex flex-column align-center text-wrap w-100",
                                    class: spell.classes,
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPopup(spell.in_game_id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "icon-container mt-1" },
                                      [
                                        _c("img", {
                                          staticClass: "bordered-icon",
                                          attrs: { src: spell.icon },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "spell-text pt-1" },
                                      [_vm._v(_vm._s(spell.name))]
                                    ),
                                    _c(
                                      "dialog-box",
                                      {
                                        attrs: {
                                          id: "spell.in_game_id",
                                          model:
                                            _vm.openPopups[spell.in_game_id],
                                          width: "650",
                                        },
                                        on: {
                                          dialogClosed: function ($event) {
                                            return _vm.closePopup(
                                              spell.in_game_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "spell-popup common-color",
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "popup-header pb-4 mb-4 d-flex align-center",
                                                attrs: { "no-gutters": "" },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pr-2 py-1",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "bordered-icon",
                                                      attrs: {
                                                        src: spell.icon,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "9" } },
                                                  [
                                                    _c("h2", [
                                                      _vm._v(
                                                        _vm._s(spell.name)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "10",
                                                      sm: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "highlighted-part",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: spell.partImage,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "spell-description",
                                                    attrs: {
                                                      cols: "10",
                                                      sm: "8",
                                                    },
                                                  },
                                                  [
                                                    spell.flavour_description !==
                                                      undefined &&
                                                    spell.flavour_description !==
                                                      null
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "font-italic",
                                                          },
                                                          [
                                                            _vm._v(
                                                              ' "' +
                                                                _vm._s(
                                                                  spell.flavour_description
                                                                ) +
                                                                '" '
                                                            ),
                                                            _c("br"),
                                                            _c("br"),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c("div", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            spell.technical_description
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("br"),
                                                    _c("div", [
                                                      _vm._v("Spell type: "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-capitalize",
                                                          class: spell.classes,
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(spell.type)
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v("Spell element: "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-capitalize",
                                                          class: spell.element,
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                spell.element
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    spell.mechanic_name !==
                                                      undefined &&
                                                    spell.mechanic_name !== null
                                                      ? _c("div", [
                                                          _vm._v(" Mechanic: "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-capitalize",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    spell.mechanic_name
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "10",
                lg: "6",
                md: "5",
                offset: "1",
                "offset-md": "0",
              },
            },
            [
              _vm.isOfferActive
                ? _c(
                    "div",
                    {
                      staticClass:
                        "box-decorated-edges-mini common-bg section-buttons",
                    },
                    [
                      _c("asset-take-offer-popup", {
                        attrs: { activeOffer: _vm.activeOffer },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOwner && !_vm.isOfferActive
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "box-decorated-edges-mini common-bg section-buttons",
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "12", md: "12" } },
                        [
                          _c("wizard-transform-popup", {
                            attrs: { wizardDTO: _vm.wizardDTO },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6", md: "12", sm: "6" } },
                        [
                          _c("wizard-transfer-popup", {
                            attrs: { nft: _vm.wizard },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6", md: "12", sm: "6" } },
                        [
                          _c("asset-create-offer-popup", {
                            attrs: {
                              assetDTO: _vm.wizardDTO,
                              assetType: _vm.assetType,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "section-details common-color common-bg box-decorated-edges-mini pa-8",
                },
                [
                  _c("div", { staticClass: "information text-capitalize" }, [
                    _c(
                      "p",
                      { staticClass: "text-truncate" },
                      [
                        _vm._v(" Owner: "),
                        _c(
                          "span",
                          {
                            directives: [{ name: "title", rawName: "v-title" }],
                            attrs: { title: _vm.assetOwner },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("shortAddress")(_vm.assetOwner))
                            ),
                          ]
                        ),
                        _c("copy-button", { attrs: { text: _vm.assetOwner } }),
                      ],
                      1
                    ),
                    _vm.wizardDTO
                      ? _c("p", [
                          _vm._v(" Wizard created on: "),
                          _c(
                            "span",
                            {
                              directives: [
                                { name: "title", rawName: "v-title" },
                              ],
                              staticClass: "text-no-wrap",
                              attrs: {
                                title: _vm._f("moment")(
                                  _vm.wizardDTO.created_on,
                                  "HH:MM Do MMMM YYYY"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      _vm.wizardDTO.created_on,
                                      "Do MMMM YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _c("p", [
                          _vm._v(" Created on: "),
                          _c(
                            "span",
                            {
                              directives: [
                                { name: "title", rawName: "v-title" },
                              ],
                              staticClass: "text-no-wrap",
                              attrs: {
                                title: _vm._f("moment")(
                                  _vm.wizard.created_on,
                                  "HH:MM Do MMMM YYYY"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      _vm.wizard.created_on,
                                      "Do MMMM YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                    _c("br"),
                    !_vm.isWizardMature
                      ? _c(
                          "p",
                          [
                            _vm._v(" Mature in: "),
                            _c("timer", {
                              attrs: {
                                "deadline-string": _vm.wizard.wizarre.mature_on,
                                title: _vm.wizard.wizarre.mature_on,
                              },
                            }),
                            !_vm.isWizardMature
                              ? _c("v-progress-linear", {
                                  staticClass:
                                    "level-progress d-sm-inline-block mx-0 mx-sm-4 mb-4 mb-sm-0",
                                  attrs: {
                                    value: _vm.maturationProgress,
                                    "background-opacity": "0",
                                    color: "white",
                                    height: "11px",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "p",
                          [
                            _vm._v(" Level: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.wizard.wizarre.experience / 1000 + 1)
                              ),
                            ]),
                            _c("v-progress-linear", {
                              staticClass:
                                "level-progress d-sm-inline-block mx-0 mx-sm-4 mb-4 mb-sm-0",
                              attrs: {
                                value:
                                  (_vm.wizard.wizarre.experience % 1000) / 10,
                                "background-opacity": "0",
                                color: "white",
                                height: "11px",
                              },
                            }),
                          ],
                          1
                        ),
                    _c("p", [
                      _vm._v(" Inner Elements: "),
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "text-no-wrap" },
                          [
                            _c("icon-element", {
                              staticClass: "element-icon",
                              attrs: {
                                element: _vm.element_1,
                                "img-size": "26px",
                              },
                            }),
                            _vm._v(" " + _vm._s(_vm.element_1) + " "),
                          ],
                          1
                        ),
                      ]),
                      _vm.element_2
                        ? _c(
                            "span",
                            { staticClass: "text-no-wrap" },
                            [
                              _vm.element_2
                                ? _c("icon-element", {
                                    staticClass:
                                      "element-icon second-element-icon",
                                    attrs: {
                                      element: _vm.element_2,
                                      "img-size": "26px",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" " + _vm._s(_vm.element_2) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("p", [
                      _vm._v(" Soul Fragments: "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.wizard.wizarre.souls_remaining) +
                            "/" +
                            _vm._s(_vm.wizard.wizarre.souls_max)
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v(" Personality: "),
                      _vm.wizard.wizarre.personality
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.wizard.wizarre.personality)),
                          ])
                        : _c("span", { staticClass: "text-none" }, [
                            _vm._v("Will be uncovered when the Wizard matures"),
                          ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "section-spacer" }),
                  _c(
                    "div",
                    { staticClass: "statistics" },
                    [
                      _c("p", { staticClass: "mb-2 font-weight-bold" }, [
                        _vm._v("Statistics:"),
                      ]),
                      !_vm.isWizardMature
                        ? _c("p", [
                            _c("span", [
                              _vm._v(
                                "Will be uncovered when the Wizard matures"
                              ),
                            ]),
                          ])
                        : _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "7",
                                    md: "12",
                                    sm: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c("WizardStatisticsGraph", {
                                    attrs: {
                                      statistics:
                                        _vm.wizardDTO.wizarre.statistics,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _vm.wizard.wizarre.reincarnated_from ||
                  _vm.wizard.wizarre.reincarnated_into
                    ? _c("div", [
                        _c("div", { staticClass: "section-spacer" }),
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(" Reincarnations: "),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "reincarnated w-100 d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap",
                          },
                          [
                            _vm.wizard.wizarre.reincarnated_from
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "reincarnation-previous d-flex flex-column align-center",
                                  },
                                  [
                                    _vm.reincarnatedFrom
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Wizard",
                                                params: {
                                                  id: _vm.reincarnatedFrom.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("mini-wizard", {
                                              attrs: {
                                                item: _vm.reincarnatedFrom,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("span", [_vm._v(" Previous ")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.wizard.wizarre.reincarnated_into
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "reincarnation-next d-flex flex-column align-center",
                                  },
                                  [
                                    _vm.reincarnatedInTo
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Wizard",
                                                params: {
                                                  id: _vm.reincarnatedInTo.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("mini-wizard", {
                                              attrs: {
                                                item: _vm.reincarnatedInTo,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("span", [_vm._v(" Next ")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "parents" }, [
                    _c("div", { staticClass: "section-spacer" }),
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v("Ancestors:"),
                    ]),
                    _vm.wizard.wizarre.is_genesis
                      ? _c("h3", { staticClass: "yellow--text" }, [
                          _vm._v(" Genesis Wizard "),
                        ])
                      : _vm.wizard.wizarre.is_firstborn
                      ? _c("h3", { staticClass: "yellow--text" }, [
                          _vm._v(" Firstborn Wizard "),
                        ])
                      : _vm.wizard.wizarre.summoned_by.length === 0
                      ? _c("h3", { staticClass: "yellow--text" }, [
                          _vm._v(" Ephemeral Ancestors "),
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "w-100 d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap",
                          },
                          _vm._l(_vm.summonedByWizards, function (item) {
                            return _c(
                              "div",
                              { key: "wiz" + item.id },
                              [
                                item.id
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Wizard",
                                            params: { id: item.id },
                                          },
                                        },
                                      },
                                      [
                                        _c("mini-wizard", {
                                          attrs: { item: item },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                  ]),
                  _vm.summonedWizards.length > 0
                    ? _c("div", { staticClass: "children" }, [
                        _c("div", { staticClass: "section-spacer" }),
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v("Descendants:"),
                        ]),
                        _vm.summonedWizards.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap",
                              },
                              _vm._l(_vm.summonedWizards, function (item) {
                                return _c(
                                  "div",
                                  { key: item.image },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Wizard",
                                            params: { id: item.id },
                                          },
                                        },
                                      },
                                      [
                                        _c("mini-wizard", {
                                          attrs: { item: item },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("div", [
                              _c("span", [
                                _vm._v("This Wizard has no descendants"),
                              ]),
                            ]),
                      ])
                    : _vm._e(),
                  _vm.usedIngredients.length > 0
                    ? _c("div", { staticClass: "ingredients" }, [
                        _c("div", { staticClass: "section-spacer" }),
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v("Enhancements:"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap",
                          },
                          _vm._l(_vm.usedIngredients, function (item, i) {
                            return _c(
                              "div",
                              { key: `enh_${i}_${item.image}` },
                              [
                                _c("mini-enhancement", {
                                  attrs: { item: item, "hide-amount": "" },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }