<template>
    <div class="d-block w-100">
        <main-button :on-click="openPopup" class="open-popup-btn">
            Drink Elixir
        </main-button>

        <dialog-box
            :model="popupOpen"
            title="Elixir Drink Pub"
            width="650px"
            v-on:dialogClosed="popupOpen=!popupOpen"
        >
            <div class="mt-5">
                <v-tabs-items v-model="selectedTab" class="transparent overflow-visible">
                    <v-tab-item key="wizards_selector">
                        <v-container class="pa-0 mb-4">
                            <h3 class="pb-2">Select wizard: </h3>
                            <v-row v-if="ownedWizards.length > 0">
                                <v-col v-for="wizard in ownedWizards" :key="`wizard_${wizard.id}`"
                                       class="d-flex align-center justify-center"
                                       cols="6" md="3" sm="4"
                                       v-on:click="selectWizard(wizard)"
                                >
                                    <mini-wizard
                                        :item="wizard"
                                    ></mini-wizard>
                                </v-col>
                            </v-row>
                            <div v-else>
                                <h3 class="text-center">- No Wizards Owned -</h3>
                            </div>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="main_tab">
                        <v-container class="pa-0">
                            <hr class="mb-6"/>
                            <v-row class="round-borders-highlight my-4 mx-8">
                                <v-col class="d-flex flex-column align-center justify-end" cols="12" sm="5">
                                    <v-img :src="wizardImageSrc"
                                           contain
                                           lazy-src="/img/wizard-shadow_250.png"
                                           max-height="20vmax"
                                           max-width="150px"
                                    />
                                    <div v-if="wizardName"
                                         class="hero-description d-flex flex-row align-top">
                                        <h4 class="ext-center">{{ wizardName }}</h4>
                                        <div
                                            v-if="!wizardDTO"
                                            class="button-square"
                                            v-on:click="openWizardSelection()"
                                        >
                                        </div>
                                    </div>
                                    <div v-else>
                                        <main-button :on-click="openWizardSelection">
                                            Select Wizard
                                        </main-button>
                                    </div>
                                </v-col>
                                <v-col class="d-flex flex-column align-center justify-center" cols="12" sm="2">
                                    <v-icon color="frames" large>mdi-plus</v-icon>
                                </v-col>
                                <v-col class="d-flex flex-column align-center justify-end" cols="12" sm="5">
                                    <v-img :src="elixirImageSrc"
                                           contain
                                           lazy-src="/img/elixir-shadow_250.png"
                                           max-height="10vmax"
                                           max-width="150px"
                                    />
                                    <div v-if="elixirName"
                                         class="hero-description d-flex flex-row align-top">
                                        <h4 class="pr-2 text-center">{{ elixirName }}</h4>
                                        <div
                                            v-if="!elixirDTO"
                                            class="button-square"
                                            v-on:click="openElixirSelection()"
                                        >
                                        </div>
                                    </div>
                                    <div v-else>
                                        <main-button :on-click="openElixirSelection">
                                            Select Elixir
                                        </main-button>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <div
                                        v-if="selectedElixir">
                                        <hr class="mb-4"/>
                                        <h3 class="white--text mb-4">{{ elixirName }}</h3>
                                        <div v-if="elixirPower"
                                             class="white--text"
                                        >
                                            {{ elixirPower }}
                                        </div>
                                        <div v-if="elixirDescription"
                                             class="font-italic purple--text text--lighten-4"
                                        >
                                            {{ elixirDescription }}
                                        </div>
                                        <div class="caption pa-2 mt-4 burn-warning white--text">
                                            Drinking Elixir will burn selected Wizard and replace with new one.
                                            New one will be copy of burned one, with new ID and modifications according
                                            to elixir effect.
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="elixirs_selector">
                        <v-container class="pa-0 mb-4">
                            <h3 class="pb-2">Select elixir: </h3>
                            <v-row v-if="ownedElixirs.length > 0">
                                <v-col v-for="elixir in ownedElixirs" :key="`elixir_${elixir.id}`"
                                       class="d-flex align-center justify-center"
                                       cols="6" md="3" sm="4"
                                       v-on:click="selectElixir(elixir)"
                                >
                                    <mini-enhancement
                                        :item="elixir"
                                    ></mini-enhancement>
                                </v-col>
                            </v-row>
                            <div v-else>
                                <h3 class="text-center">- No Elixirs Owned -</h3>
                            </div>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
                <v-card-actions
                    class="pa-0 pt-4"
                >
                    <v-container class="pa-0 mx-sm-16">
                        <v-row dense>
                            <v-col
                                v-if="!(selectedTab === MAIN_TAB)"
                                cols="12">
                                <main-button
                                    :on-click="goHome"
                                >
                                    Back
                                </main-button>
                            </v-col>
                            <v-col
                                v-if="(selectedTab === MAIN_TAB)"
                                cols="12" sm="6">
                                <main-button
                                    :disabled="elixirsAllowed"
                                    :loading="checkingElixirsAllowance || elixirsAllowingInProgress"
                                    :on-click="allowElixirs"
                                >
                                    Allow Elixirs
                                </main-button>
                            </v-col>
                            <v-col
                                v-if="(selectedTab === MAIN_TAB)"
                                cols="12" sm="6">
                                <main-button
                                    :disabled="wizardsAllowed"
                                    :loading="checkingWizardsAllowance || wizardsAllowingInProgress"
                                    :on-click="allowWizards"
                                >
                                    Allow Wizards
                                </main-button>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-if="(selectedTab === MAIN_TAB)"
                                cols="12">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <main-button
                                                :disabled="validationErrors.length > 0"
                                                :on-click="drinkElixir"
                                                glow
                                            >
                                                Drink Elixir
                                            </main-button>
                                        </div>
                                    </template>
                                    <div>
                                        <span v-if="validationErrors.length === 0">Click to drink elixir</span>
                                        <ul v-else>
                                            <li v-for="(error, i) in validationErrors" :key="'err_' + i">{{
                                                    error
                                                }}
                                            </li>
                                        </ul>
                                    </div>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </div>
        </dialog-box>
        <dialog-box
            :model="waitingForConfirmation"
            persistent
            title="Waiting for drinking confirmation"
            width="750"
            v-on:dialogClosed="waitingForConfirmation=!waitingForConfirmation"
        >
            <v-img class="round-borders-highlight"
                   contain
                   max-width="80vw"
                   src="/img/transformation/waiting.png"
            />
            <v-progress-linear class="mt-4" color="white" height="5" indeterminate></v-progress-linear>
        </dialog-box>
        <dialog-box
            :model="transformingWizard"
            persistent
            title="Wizard is transforming"
            width="750"
            v-on:dialogClosed="transformingWizard=!transformingWizard"
        >
            <v-img class="round-borders-highlight"
                   contain
                   max-width="80vw"
                   src="/img/transformation/transforming.png"
            />
            <v-progress-linear class="mt-4" color="white" height="5" indeterminate></v-progress-linear>
        </dialog-box>
        <dialog-box :model="newWizardTransformed"
                    highlight-title
                    title="Congratulations!"
                    width="500px"
                    v-on:dialogClosed="newWizardTransformed = !newWizardTransformed;"
        >
            <hr class="mt-2"/>
            <div v-if="transformedWizard !== null"
                 class=" text--primary d-flex flex-column align-center justify-space-between">
                <v-row class="round-borders-highlight my-4">
                    <v-col class="d-flex flex-column align-center justify-end" cols="12" sm="5">
                        <v-img :src="wizardImageSrc"
                               class="mb-4"
                               contain
                               lazy-src="/img/wizard-shadow_250.png"
                               max-height="20vmax"
                               max-width="150px"
                        />
                        <div class="hero-description d-flex flex-row align-center">
                            <h4 class="pr-2 text-center">{{ wizardName }}</h4>
                            <div
                                v-if="!wizardDTO"
                                class="button-square"
                                v-on:click="openWizardSelection()"
                            >
                            </div>
                        </div>
                    </v-col>
                    <v-col class="d-flex flex-column align-center justify-center" cols="12" sm="2">
                        <v-icon class="hidden-xs-only" color="golden" large>mdi-arrow-right</v-icon>
                        <v-icon class="hidden-sm-and-up" color="golden" large>mdi-arrow-down</v-icon>
                    </v-col>
                    <v-col class="d-flex flex-column align-center justify-end" cols="12" sm="5">
                        <v-img :src="transformedWizard.thumbnail_300"
                               class="mb-4"
                               contain
                               lazy-src="/img/wizard-shadow_250.png"
                               max-height="20vmax"
                               max-width="150px"
                        />
                        <div class="hero-description d-flex flex-row align-center">
                            <h4 class="pr-2 text-center golden--text">{{ transformedWizard.name }}</h4>
                        </div>
                    </v-col>
                </v-row>
                <span class="caption pa-4 text-center burn-warning mb-4">
                    You've transformed your wizard {{ wizardName }}
                    <br/>
                    to a new wizard
                    <span class="golden--text">{{ transformedWizard.name }}</span>
                    </span>

                <span>You can check it on</span>
                <v-btn
                    :to="{ name: 'Wizard', params: {id: transformedWizard.id}}"
                    color="purple"
                >
                    Wizard #{{ transformedWizard.id }}
                </v-btn>
                <div>

                </div>
            </div>
        </dialog-box>

        <dialog-box id="ErrorConfirmation" :model="errorDialogOpen"
                    title="Error occurred" width="auto"
                    v-on:dialogClosed="handleErrorDialogClose"
        >
            <img class="error-image rounded" src="/img/summoning/png/error.png"/>
        </dialog-box>
    </div>
</template>

<script>

import MainButton from "../../ui/main-button";
import DialogBox from "../../dialog-box";
import AssetDTO from "@/classes/asset/AssetDTO";
import {AssetType} from "@/classes/asset/AssetType";
import MiniEnhancement from "@/components/assets/enhancements/mini-enhancement";
import MiniWizard from "@/components/assets/wizards/mini-wizard";
import AssetService from "@/services/assetService";
import ElixirContract from "@/services/contracts/elixirContract";
import {UserWalletError} from "@/errors/WalletErrors";
import {apiServiceVue} from "@/services/apiService.vue";

export default {
    components: {MiniWizard, MiniEnhancement, DialogBox, MainButton},
    props: {
        wizardDTO: AssetDTO,
        elixirDTO: AssetDTO,
    },
    data() {
        return {
            ASSET_TYPES: AssetType,
            IMPLEMENTED_ELIXIRS: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 38, 39, 40, 41, 42, 43, 44],
            WIZARDS_TAB: 0,
            MAIN_TAB: 1,
            ELIXIRS_TAB: 2,
            selectedTab: 1,
            mounted: false,
            elixirContract: undefined,
            assetService: undefined,
            popupOpen: false,
            errorDialogOpen: false,
            selectedWizard: undefined,
            selectedElixir: undefined,
            fetchingElixirs: false,
            ownedElixirs: [],
            fetchingWizards: false,
            ownedWizards: [],
            wizardsAllowed: false,
            elixirsAllowed: false,
            wizardsAllowingInProgress: false,
            elixirsAllowingInProgress: false,
            checkingWizardsAllowance: false,
            checkingElixirsAllowance: false,
            waitingForConfirmation: false,
            transformingWizard: false,
            newWizardTransformed: false,
            transformedWizard: {},
        }
    },
    mounted() {
        this.mounted = true;

        this.selectedWizard = this.wizardDTO;
        this.selectedElixir = this.elixirDTO;

        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    destroyed() {
        this.mounted = false;
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        elixirImageSrc() {
            return this.selectedElixir?.image || "/img/elixir-shadow_250.png";
        },
        wizardImageSrc() {
            return this.selectedWizard?.image || "/img/wizard-shadow_250.png";
        },
        elixirName() {
            return this.selectedElixir?.name || undefined;
        },
        wizardName() {
            return this.selectedWizard?.name || undefined;
        },
        elixirDescription() {
            return this.selectedElixir?.description || undefined;
        },
        elixirPower() {
            return this.selectedElixir?.effect || undefined;
        },
        validationErrors() {
            return [
                {
                    validation: !!this.selectedElixir,
                    errorText: 'Elixir should be selected.'
                },
                {
                    validation: !!this.selectedWizard,
                    errorText: 'Wizard should be selected.'
                },
                {
                    validation: this.elixirsAllowed,
                    errorText: 'Elixirs should be allowed to drink.'
                },
                {
                    validation: this.wizardsAllowed,
                    errorText: 'Wizards should be allowed to burn.'
                },
            ]
                .filter(validation => !validation.validation)
                .map(validation => validation.errorText);
        },

    },
    methods: {
        init() {
            this.fetchingElixirs = true;
            this.fetchingWizards = true;

            this.assetService = new AssetService(this.web3, this.flashMessage);

            this.assetService.fetchMyAssets([this.ASSET_TYPES.ENHANCEMENT, this.ASSET_TYPES.WIZARD])
                .then(assets => {
                    this.ownedElixirs = assets
                        .filter(asset => asset.type === this.ASSET_TYPES.ENHANCEMENT)
                        .filter(asset => this.IMPLEMENTED_ELIXIRS.includes(Number(asset.id)));
                    this.fetchingElixirs = false;

                    this.ownedWizards = assets
                        .filter(asset => asset.type === this.ASSET_TYPES.WIZARD);
                    this.fetchingWizards = false;
                });

            this.elixirContract = new ElixirContract(this.web3);

            this.checkingElixirsAllowance = true;
            this.elixirContract.enhancementSpendCheckAllowance()
                .then(result => {
                    this.elixirsAllowed = result;
                    this.checkingElixirsAllowance = false;
                });

            this.checkingWizardsAllowance = true;
            this.elixirContract.wizardBurnCheckAllowance()
                .then(result => {
                    this.wizardsAllowed = result;
                    this.checkingWizardsAllowance = false;
                });
        },
        openPopup() {
            this.selectedWizard = this.wizardDTO;
            this.selectedElixir = this.elixirDTO;
            this.selectedTab = this.MAIN_TAB;
            this.errorDialogOpen = false;
            this.waitingForConfirmation = false;
            this.transformingWizard = false;
            this.newWizardTransformed = false;
            this.popupOpen = true;
        },
        goHome() {
            this.selectedTab = this.MAIN_TAB;
        },
        openElixirSelection() {
            this.selectedTab = this.ELIXIRS_TAB
        },
        openWizardSelection() {
            this.selectedTab = this.WIZARDS_TAB
        },
        selectElixir(elixir) {
            this.selectedElixir = new AssetDTO(elixir);
            this.selectedTab = this.MAIN_TAB;
        },
        selectWizard(wizard) {
            this.selectedWizard = new AssetDTO(wizard);
            this.selectedTab = this.MAIN_TAB;
        },
        async allowElixirs() {
            this.elixirsAllowingInProgress = true;
            this.elixirContract.enhancementSpendApprove()
                .then((_) => {
                    this.elixirsAllowed = true;
                    this.$forceUpdate(); // update buttons status
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.elixirsAllowingInProgress = false;
                });

        },
        async allowWizards() {
            this.wizardsAllowingInProgress = true;
            this.elixirContract.wizardBurnApprove()
                .then((_) => {
                    this.wizardsAllowed = true;
                    this.$forceUpdate(); // update buttons status
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.wizardsAllowingInProgress = false;
                });
        },
        async drinkElixir() {
            this.popupOpen = false;
            this.waitingForConfirmation = true;

            this.elixirContract.drinkElixir(this.selectedWizard.id, this.selectedElixir.id)
                .then(async (response) => {
                    this.waitingForConfirmation = false;
                    this.transformingWizard = true;
                    const newWizardId = response.events.WizardDrankElixir.returnValues.newWizardId;
                    this.transformedWizard = new AssetDTO(await this.waitAndFetchWizardData(newWizardId));
                    this.transformingWizard = false;
                    this.newWizardTransformed = true;
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                    }
                    this.errorDialogOpen = true;
                })
                .finally(() => {
                    this.waitingForConfirmation = false;
                    this.transformingWizard = false;
                    // this.newWizardTransformed = false;
                    this.popupOpen = false;
                })
        },
        async waitAndFetchWizardData(id) {
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'many/' + id;
            while (this.mounted) {
                await (new Promise(resolve => {
                    setTimeout(resolve, 2000)
                })) // sleep

                const response = await apiServiceVue.getFullResponse(url);
                if (response.status === 200) {
                    await (new Promise(resolve => {
                        setTimeout(resolve, 5000)
                    }))
                    return (await response.json()).at(0);
                }
            }
        },
        handleErrorDialogClose() {
            this.errorDialogOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>

hr {
    background-color: rgb(100, 71, 130);
    border: none;
    height: 1px;
}

.burn-warning {
    background-color: rgb(95, 60, 131);
}

.open-popup-btn.main-button {
    min-width: 120px;
}

.button-square {
    position: absolute;
    bottom: -35px;
    left: 50%;
    margin-left: -20px;

    flex-shrink: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-size: 100% 100%;
    background-image: url("/img/summoning/png/button-replace@72x.png");

    &:hover {
        background-image: url("/img/summoning/png/button-replace-hoover@72x.png");
    }

}

.hero-description {
    min-height: 50px;
    position: relative;
}

.round-borders-highlight {
    background-color: rgb(80, 53, 111);
    border: 3px solid rgb(169, 136, 99);
    border-radius: 30px;

}
</style>
