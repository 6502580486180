<template>
    <Radar
        :chart-data="chartData"
        :chart-options="chartOptions"

    />
</template>

<script>

import {Radar} from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    PointElement,
    LineElement,
    RadialLinearScale,
    Filler, Tooltip, Legend
} from 'chart.js'

ChartJS.register(
    PointElement,
    RadialLinearScale,
    LineElement,
    Filler,
    Tooltip,
    Legend
)

export default {
    components: {
        Radar,
    },
    props: {
        statistics: {
            type: Object,
        }
    },
    data() {
        return {
            statisticsBoundaries: {
                health: {
                    min: 137,
                    max: 210
                },
                mana: {
                    min: 57,
                    max: 87
                },
                power: {
                    min: 96,
                    max: 139
                },
                luck: {
                    min: 1,
                    max: 34
                },
                will: {
                    min: 16,
                    max: 73
                },
                spirit: {
                    min: 30,
                    max: 53
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    r: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        ticks: {
                            stepSize: 25,
                            display: false,
                        },
                        angleLines: {
                            color: 'white'
                        },
                        grid: {
                            color: 'grey'
                        },
                        pointLabels: {
                            color: 'white',
                            font: {
                                size: 12
                            }
                        }
                    },

                },
            }
        }
    },
    computed: {
        computedStatistics() {
            return {
                health: {
                    value: this.statistics.health,
                    label: `Health: ${this.statistics.health}`,
                    evaluation: this.evaluateStatistic(this.statistics.health, this.statisticsBoundaries.health.min, this.statisticsBoundaries.health.max)
                },
                mana: {
                    value: this.statistics.mana,
                    label: `Mana: ${this.statistics.mana}`,
                    evaluation: this.evaluateStatistic(this.statistics.mana, this.statisticsBoundaries.mana.min, this.statisticsBoundaries.mana.max)
                },
                power: {
                    value: this.statistics.power,
                    label: `Power: ${this.statistics.power}`,
                    evaluation: this.evaluateStatistic(this.statistics.power, this.statisticsBoundaries.power.min, this.statisticsBoundaries.power.max)
                },
                luck: {
                    value: this.statistics.luck,
                    label: `Luck: ${this.statistics.luck}`,
                    evaluation: this.evaluateStatistic(this.statistics.luck, this.statisticsBoundaries.luck.min, this.statisticsBoundaries.luck.max)
                },
                will: {
                    value: this.statistics.will,
                    label: `Will: ${this.statistics.will}`,
                    evaluation: this.evaluateStatistic(this.statistics.will, this.statisticsBoundaries.will.min, this.statisticsBoundaries.will.max)
                },
                spirit: {
                    value: this.statistics.spirit,
                    label: `Spirit: ${this.statistics.spirit}`,
                    evaluation: this.evaluateStatistic(this.statistics.spirit, this.statisticsBoundaries.spirit.min, this.statisticsBoundaries.spirit.max)
                },
            }
        },
        chartData() {
            const labels = [
                this.computedStatistics.health.label,
                this.computedStatistics.mana.label,
                this.computedStatistics.will.label,
                this.computedStatistics.luck.label,
                this.computedStatistics.spirit.label,
                this.computedStatistics.power.label,
            ]
            const datasets = [{
                data: [
                    this.computedStatistics.health.evaluation,
                    this.computedStatistics.mana.evaluation,
                    this.computedStatistics.will.evaluation,
                    this.computedStatistics.luck.evaluation,
                    this.computedStatistics.spirit.evaluation,
                    this.computedStatistics.power.evaluation,
                ],
                fill: true,
                pointRadius: 3,
                pointHoverRadius: 3,
                pointHitRadius: 3,
                backgroundColor: 'rgba(181,147,101,0.2)',
                borderColor: '#f9c017',
                pointBackgroundColor: 'white',
                pointBorderColor: '#f9c017',
            }];
            return {
                labels, datasets,
            }
        },
    },
    methods: {
        evaluateStatistic(value, min, max) {
            const normalizedValue = Math.max(Math.min(value, max), min);
            const valueGain = normalizedValue - min;
            const maxValueGain = max - min;
            const valueEvaluation = (valueGain / maxValueGain) * 100;
            return Math.round(valueEvaluation);
        },
    }
}
</script>

<style scoped>

</style>
